import { getUserDefaultLang } from "~/utils/lang";

export default function useLanguages() {
    const nuxtApp = useNuxtApp();
    const $t = nuxtApp.$t;
    const { user } = useUserSession();

    const locale = useCookie("lang", {
        default: () =>
            getUserDefaultLang(user?.value?.profile?.user_metadata?.crm_data),
    });

    const languages = {
        en: "eng",
        zh: "cht",
        de: "deu",
        es: "esp",
        ru: "rus",
        fr: "fra",
        it: "ita",
    };

    const setLocale = (lang: string) => {
        locale.value = lang;
    };

    const currentLanguage = computed({
        get: () => shortToLong(locale.value),
        set: (lang) => setLocale(longToShort(lang)),
    });

    function shortToLong(lang: string) {
        return languages[lang as keyof typeof languages];
    }

    function longToShort(lang: string) {
        return Object.keys(languages).find(
            (key) => languages[key as keyof typeof languages] === lang
        ) as keyof typeof languages;
    }

    return {
        languages,
        currentLanguageShort: locale,
        currentLanguage,
        setCurrentLanguage: setLocale,
        shortToLong,
        longToShort,
        $t,
    };
}
